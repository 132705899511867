import {NetworkAPIVersion, NetworkParamType} from '@common/network/network.utils';

export default {
  getSources: {
    url: 'sources/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      types: [NetworkParamType.string],
      indexed: [NetworkParamType.string],
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      fields: [NetworkParamType.string],
    },
  },
  getSourcesOptions: {
    url: 'sources/search-books/options/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getSourcesContentOptions: {
    url: 'sources/search-content/options/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getSource: {
    url: 'sources/{id}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      exclude: [NetworkParamType.string],
    },
  },
  searchBooksContent: {
    url: 'sources/search-content/',
    method: 'get',
    autoCancel: true,
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      q: [NetworkParamType.string],
      last_name: [NetworkParamType.string],
      clan_id: [NetworkParamType.string],
      clan_name: [NetworkParamType.string],
      source_type: [NetworkParamType.string],
      ancestral_place_id: [NetworkParamType.string],
      ancestral_level_1_ids: [NetworkParamType.string],
      ancestral_level_2_ids: [NetworkParamType.string],
      ancestral_level_3_ids: [NetworkParamType.string],
      categories: [NetworkParamType.string],
      category_id: [NetworkParamType.string],

      country: [NetworkParamType.string],
      place_name: [NetworkParamType.string],
      place_id: [NetworkParamType.string],
      collection_id: [NetworkParamType.string],
      source_id: [NetworkParamType.string],

      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      only_meta: [NetworkParamType.boolean],
      only_count: [NetworkParamType.boolean],
    },
    response: data => {
      const facets = data.meta.facets;
      if (!facets) {
        return data;
      }
      data.meta.facets = {
        ...facets,
        ancestral_province_id: facets.ancestral_province_id
          ? facets.ancestral_province_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        ancestral_level_1_id: facets.ancestral_level_1_id
          ? facets.ancestral_level_1_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        ancestral_level_2_id: facets.ancestral_level_2_id
          ? facets.ancestral_level_2_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        ancestral_level_3_id: facets.ancestral_level_3_id
          ? facets.ancestral_level_3_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        country: facets.country
          ? facets.country.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        clans: facets.clans ? facets.clans.map(item => (item.name === 'none' ? {...item, name: 'None'} : item)) : [],
      };
      return data;
    },
  },
  searchSources: {
    url: 'sources/search-books/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
    params: {
      q: [NetworkParamType.string],
      auto_place_id: [NetworkParamType.string],
      clan_name: [NetworkParamType.string],
      category_id: [NetworkParamType.string],
      source_type: [NetworkParamType.string],
      ancestral_place_id: [NetworkParamType.string],
      ancestral_level_1_ids: [NetworkParamType.string],
      ancestral_level_2_ids: [NetworkParamType.string],
      ancestral_level_3_ids: [NetworkParamType.string],
      publication_year: [NetworkParamType.number],
      record_format: [NetworkParamType.string],
      has_ocr: [NetworkParamType.string],
      has_images: [NetworkParamType.string],
      categories: [NetworkParamType.string],
      country: [NetworkParamType.string],
      collection_id: [NetworkParamType.string],

      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      only_meta: [NetworkParamType.boolean],
      only_count: [NetworkParamType.boolean],
      facets_list: [NetworkParamType.string],
    },
    response: data => {
      const facets = data.meta.facets;
      if (!facets) {
        return data;
      }
      data.meta.facets = {
        ...facets,
        ancestral_province_id: facets.ancestral_province_id
          ? facets.ancestral_province_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        ancestral_level_1_id: facets.ancestral_level_1_id
          ? facets.ancestral_level_1_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        ancestral_level_2_id: facets.ancestral_level_2_id
          ? facets.ancestral_level_2_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        ancestral_level_3_id: facets.ancestral_level_3_id
          ? facets.ancestral_level_3_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        country: facets.country
          ? facets.country.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        clans: facets.clans ? facets.clans.map(item => (item.name === 'none' ? {...item, name: 'None'} : item)) : [],
      };
      return data;
    },
  },
  getImage: {
    url: 'sources/{id}/image/{imageNumber}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      search: [NetworkParamType.string],
    },
  },
  getImagePreview: {
    url: 'sources/{id}/image-preview/{imageNumber}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    responseType: 'arraybuffer',
  },
  getImageOCR: {
    url: 'sources/{id}/image-ocr/{imageNumber}/?ocr_type={ocrType}',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getImageSearchPreview: {
    url: 'sources/{id}/image-search-preview/{imageNumber}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    responseType: 'arraybuffer',
    params: {
      search: [NetworkParamType.string],
      is_horizontal: [NetworkParamType.boolean],
    },
  },
  bulkRemoveImages: {
    url: 'source-image/bulk-remove/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      image_ids: [NetworkParamType.array],
    },
  },
  getObituariesSources: {
    url: 'obituaries-sources/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  searchImagesContent: {
    url: 'source-image/search/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      source_id: [NetworkParamType.string],
      q: [NetworkParamType.string],
      offset: [NetworkParamType.number],
    },
  },
  createMention: {
    url: 'mention/create/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      image_ids: [NetworkParamType.string],
      person_id: [NetworkParamType.string],
      source_id: [NetworkParamType.string],
    },
  },
  publishMentions: {
    url: 'mention/publish/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      person_id: [NetworkParamType.string],
      source_id: [NetworkParamType.string],
    },
  },
  searchMentions: {
    url: 'sources/search-mentions/',
    method: 'get',
    autoCancel: true,
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      first_name: [NetworkParamType.string],
      surname: [NetworkParamType.string],
      auto_place_id: [NetworkParamType.string],
      auto_place_name: [NetworkParamType.string],
      arrival_place_id: [NetworkParamType.string],
      clan_name: [NetworkParamType.string],
      residence_location: [NetworkParamType.array],
      residence_location_name: [NetworkParamType.array],
      residence_from: [NetworkParamType.string],
      residence_to: [NetworkParamType.string],
      birth_location_id: [NetworkParamType.string],
      birth_location_name: [NetworkParamType.string],
      death_location_id: [NetworkParamType.string],
      death_location_name: [NetworkParamType.string],
      birth_year: [NetworkParamType.number],
      death_year: [NetworkParamType.number],
      is_birth_location_optional: [NetworkParamType.boolean],
      is_death_location_optional: [NetworkParamType.boolean],
      is_birth_year_optional: [NetworkParamType.boolean],
      is_death_year_optional: [NetworkParamType.boolean],
      is_residence_optional: [NetworkParamType.boolean],
      category_id: [NetworkParamType.string],
      source_types: [NetworkParamType.string],
      categories: [NetworkParamType.string],
      source_id: [NetworkParamType.string],
      country_id: [NetworkParamType.string],
      gender: [NetworkParamType.string],
      has_images: [NetworkParamType.string],
      cemetery_id: [NetworkParamType.string],
      cemetery_place_id: [NetworkParamType.string],
      ancestral_place_id: [NetworkParamType.string],
      record_year: [NetworkParamType.string],
      with_fuzzy_first_name: [NetworkParamType.boolean],
      with_fuzzy_surname: [NetworkParamType.boolean],
      collection_id: [NetworkParamType.string],

      limit: [NetworkParamType.number],
      facts: [NetworkParamType.dynamicArray],
      relatives: [NetworkParamType.dynamicArray],
      offset: [NetworkParamType.number],
      only_meta: [NetworkParamType.boolean],
      only_count: [NetworkParamType.boolean],
      facets_list: [NetworkParamType.string],
    },
    response: data => {
      const facets = data.meta.facets;
      if (!facets) {
        return data;
      }
      data.meta.facets = {
        ...facets,
        ancestral_province_id: facets.ancestral_province_id
          ? facets.ancestral_province_id.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        country: facets.country
          ? facets.country.map(item => (item.name === 'none' ? {...item, name: 'None'} : item))
          : [],
        clans: facets.clans ? facets.clans.map(item => (item.name === 'none' ? {...item, name: 'None'} : item)) : [],
      };
      return data;
    },
  },
  searchSourcesAutocomplete: {
    url: 'sources/search-sources/autocomplete/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      q: [NetworkParamType.string],
      source_types: [NetworkParamType.string],
    },
  },
  getCollectionDetails: {
    url: 'collections/{id}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      fields: [NetworkParamType.string],
    },
  },
  searchCollections: {
    url: 'collections/search/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
    params: {
      q: [NetworkParamType.string],
    },
  },
  searchMentionsOptions: {
    url: 'mention/options/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getPersonMentions: {
    url: 'person-mentions/{code}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getPersonMentionsImages: {
    url: 'person-mentions/{code}/images/{source_id}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      for_hint: [NetworkParamType.boolean],
    },
  },

  getSearchCategoriesList: {
    url: 'search-categories/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
  },
  getSearchCategory: {
    url: 'search-categories/{id}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
  },
};
