import network from '@common/network/network';
import pickBy from 'lodash/pickBy';

export default {
  searchAllRecordsAction(context, payload) {
    context.commit('setSearchAllRecordsLoadingState', true);
    let {page, ...filters} = payload;
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    let params = {
      limit,
      offset,
      ...filters,
    };
    if (params.is_flexible) {
      params.is_birth_location_optional = true;
      params.is_death_location_optional = true;
      params.is_birth_year_optional = true;
      params.is_death_year_optional = true;
      params.is_residence_optional = true;
    }
    params = pickBy(params, value => !!value);

    return new Promise((resolve, reject) => {
      network.source
        .searchMentions(params)
        .then(response => {
          context.commit('setSearchAllRecordsListState', response.objects);
          context.commit('setSearchAllRecordsMetaState', response.meta);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSearchAllRecordsLoadingState', false);
        });
    });
  },
  searchAllRecordsSilentAction(context, filters) {
    let apiFilters = filters ? {...filters} : {};
    if (apiFilters.is_flexible) {
      apiFilters.is_birth_location_optional = true;
      apiFilters.is_death_location_optional = true;
      apiFilters.is_birth_year_optional = true;
      apiFilters.is_death_year_optional = true;
      apiFilters.is_residence_optional = true;
    }
    return network.source.searchMentions(apiFilters);
  },
  fetchAllRecordsOptionsAction(context) {
    context.commit('setSearchAllRecordsOptionsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source.searchMentionsOptions().then(response => {
        context.commit('setSearchAllRecordsOptionsState', response);
        resolve(response);
      });
    }).finally(() => {
      context.commit('setSearchAllRecordsOptionsLoadingState', false);
    });
  },

  askAiBySearchPromptAction(context, {search_prompt}) {
    context.commit('setAiSearchPromptLoadingState', true);
    return new Promise((resolve, reject) => {
      network.ai
        .searchPrompt({search_prompt})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setAiSearchPromptLoadingState', false);
        });
    });
  },
  asAiFollowUpAction(context, {search_url, follow_up_prompt}) {
    context.commit('setAiSearchFollowUpLoadingState', true);
    return new Promise((resolve, reject) => {
      network.ai
        .searchFollowUp({search_url, follow_up_prompt})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setAiSearchFollowUpLoadingState', false);
        });
    });
  },
};
