import {sourceTypes} from '@common/utils/consts.source';

export function getPlaceLabelBySourceType(sourceType) {
  return [sourceTypes.CEMETERY, sourceTypes.BURIAL_RECORD, sourceTypes.IMMIGRATION_CASE_FILES].includes(sourceType)
    ? 'Place'
    : 'Publication Place';
}

export function hasClassifiedData(data) {
  return data && typeof data === 'string' && data.includes('⬯');
}
export function preload(srcArray, index) {
  index = index || 0;
  if (srcArray && srcArray.length > index) {
    let img = new Image();
    img.onload = function () {
      preload(srcArray, index + 1);
    };
    img.src = srcArray[index];
  }
}

export function getClearSourcesSearchFacets(filters, facets) {
  if (filters.ancestral_level_3_ids) {
    return facets;
  }
  if (filters.ancestral_level_2_ids) {
    return {...facets, ancestral_level_3_id: []};
  }
  if (filters.ancestral_level_1_ids) {
    return {...facets, ancestral_level_2_id: [], ancestral_level_3_id: []};
  }
  return facets;
}
