import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  create: {
    url: 'access-item/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      person_code: [NetworkParamType.string],
    },
  },
};
