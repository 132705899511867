import consts from './consts';

class UserStorage {
  static getUserAPIKey() {
    if (!localStorage) {
      return null;
    }
    let store = localStorage.getItem(consts.store);
    store = store ? JSON.parse(store) : {};
    const user = store.userState;
    return user && user.key ? `${user.username}:${user.key}` : null;
  }
  static getSessionId() {
    if (!localStorage) {
      return null;
    }
    return localStorage.getItem('session_key');
  }
  static setSessionId(session_key) {
    if (!localStorage) {
      return null;
    }
    localStorage.setItem('session_key', session_key);
  }
  static getImpersonatedUserID() {
    if (!localStorage) {
      return null;
    }
    let store = localStorage.getItem(consts.store);
    store = store ? JSON.parse(store) : {};
    const user = store.userState;
    return user && user.impersonatedUser ? user.impersonatedUser.email : null;
  }
}

export default UserStorage;
