export default {
  list: null,
  meta: {},
  loading: null,
  options: null,
  optionsLoading: null,
  form: {
    first_name: null,
    surname: null,
    auto_place: null,
    residence_location: null,
    residence_from: null,
    residence_to: null,
    gender: null,
    birth_location: null,
    death_location: null,
    birth_year: null,
    death_year: null,
    category_id: null,
    source_types: null,
    source_location: null,
    source_id: null,
    cemetery_id: null,
    ancestral_place: null,
    clan_name: null,
    record_year: null,
    has_images: null,
    with_fuzzy_first_name: null,
    with_fuzzy_surname: null,
    is_flexible: null,
    facts: null,
    relatives: null,
    collection: null,
  },

  aiSearchPromptLoading: false,
  aiSearchFollowUpLoading: false,
  aiSearchQuery: '',
  aiUsedRecently: false,
};
