import {NetworkAPIVersion, NetworkParamType} from '@common/network/network.utils';

export default {
  searchPrompt: {
    url: 'api/search-assist/search-for-prompt/',
    method: 'get',
    apiVersion: NetworkAPIVersion.noVersion,
    params: {
      search_prompt: [NetworkParamType.string],
    },
  },
  searchFollowUp: {
    url: 'api/search-assist/search-follow-up/',
    method: 'get',
    apiVersion: NetworkAPIVersion.noVersion,
    params: {
      search_url: [NetworkParamType.string],
      follow_up_prompt: [NetworkParamType.string],
    },
  },
};
