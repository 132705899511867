import UserStorage from '@common/utils/user.storage';
import axios from 'axios';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import mapKeys from 'lodash/mapKeys';
import merge from 'lodash/merge';
import posthog from 'posthog-js';
import qs from 'qs';

import accessItem from './network.accessItem';
import ai from './network.ai';
import cemetery from './network.cemetery';
import newspaper from './network.cemtery.newspaper';
import clan from './network.clan';
import clanSpelling from './network.clan.spelling';
import clanSurname from './network.clan.surname';
import common from './network.common';
import familyTree from './network.familyTree';
import familyTreeHints from './network.familyTree.hints';
import familyTreeLibrary from './network.familyTree.library';
import familyTreeLineage from './network.familyTree.lineage';
import familyTreePerson from './network.familyTree.person';
import familyTreeRelations from './network.familyTree.relations';
import familyTreeSaved from './network.familyTree.saved';
import fws from './network.fws';
import help from './network.help';
import library from './network.library';
import member from './network.member';
import ocr from './network.ocr';
import order from './network.order';
import payment from './network.payment';
import pedigree from './network.pedigree';
import ownedPedigree from './network.pedigree.owned';
import savedPedigree from './network.pedigree.saved';
import place from './network.place';
import placeMigration from './network.place.migration';
import prospectRequest from './network.prospectRequest';
import source from './network.source';
import subscription from './network.subscription';
import switches from './network.switches';
import {_filterValidateParams, NetworkAPIBase, NetworkAPIVersion, NetworkParamType} from './network.utils';
import verify from './network.verify';
import wiki from './network.wiki';

const networkActions = {
  clan,
  place,
  member,
  pedigree,
  wiki,
  help,
  verify,
  prospectRequest,
  common,
  library,
  accessItem,
  fws,
  familyTree,
  familyTreePerson,
  familyTreeRelations,
  familyTreeLibrary,
  familyTreeLineage,
  familyTreeSaved,
  familyTreeHints,
  ownedPedigree,
  savedPedigree,
  payment,
  switches,
  clanSpelling,
  clanSurname,
  placeMigration,
  cemetery,
  newspaper,
  source,
  subscription,
  order,
  ocr,
  ai,
};
const SESSION_HEADER = 'x-mcr-session-id';
class Network {
  constructor() {
    let self = this;
    mapKeys(networkActions, (collection, collectionKey) => {
      mapKeys(collection, (request, requestKey) => {
        collection[requestKey] = transformNetworkActionToHttpRequest(request);
      });
      self[collectionKey] = collection;
    });

    function transformNetworkActionToHttpRequest(networkAction) {
      return (params, uploadBlock, customUrl, errorBlock) => {
        if (isUndefined(params)) {
          params = {};
        }

        let filteredParam = _filterValidateParams(networkAction.params, params);

        if (isUndefined(networkAction.apiVersion)) {
          networkAction.apiVersion = NetworkAPIVersion.v1_version + '/';
        }

        let url = customUrl ? networkAction.apiVersion + customUrl : networkAction.apiVersion + networkAction.url;

        url = url.replace(/{(.+?)}/gi, (matched, key) => {
          return params[key];
        });

        // console.debug(
        //   "Requesting on:",
        //   networkAction.multipart ? "Multipart" : "NonMultipart",
        //   url,
        //
        //   filteredParam
        // );

        if (networkAction.restful && 'ID' in filteredParam) {
          url += filteredParam['ID'] + '/';
          // if (networkAction.method != "put") {
          //   filteredParam = {};
          // }
        }
        let options = {
          url: url,
          method: networkAction.method,
          responseType: networkAction.responseType,
        };

        if (networkAction.autoCancel) {
          if (networkAction.controller) {
            networkAction.controller.abort({abort: true});
          }
          const controller = new AbortController();
          options.signal = controller.signal;
          networkAction.controller = controller;
        }

        if (!networkAction.multipart) {
          merge(options, {
            data: filteredParam,
          }); // End of merging params

          // set different key of param with different method
          if (networkAction.method === 'get') {
            options.params = filteredParam;
          } else {
            options.data = filteredParam;
          }
        } else {
          /*** multipart
           * */

          let formData = new FormData();
          mapKeys(filteredParam, (value, key) => {
            if (networkAction.params[key].includes(NetworkParamType.filesArray)) {
              for (let i = 0; i < value.length; i++) {
                let file = value[i];
                formData.append(key, file);
              }
            } else {
              formData.append(key, value);
            }
          });

          merge(options, {
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            onUploadProgress: function (progressEvent) {
              // 使用本地 progress 事件做任何你想要做的

              if (uploadBlock) {
                uploadBlock(progressEvent);
              }
            },
          });
        }
        let httpCreateParams = {
          baseURL: NetworkAPIBase,
          timeout: 5 * 60000,
          paramsSerializer: function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'});
          },
        };

        if (networkAction.disableQs) {
          httpCreateParams = {
            baseURL: NetworkAPIBase,
            timeout: 5 * 60000,
          };
        }

        httpCreateParams['headers'] = {
          'x-referrer': window.location.href,
          'x-analytics-session-id': posthog.get_distinct_id() || '',
        };

        const authorization = self.authorizationHeader();
        if (authorization) {
          httpCreateParams['headers'] = {
            ...httpCreateParams['headers'],
            Authorization: authorization,
          };
        }
        const sessionKey = UserStorage.getSessionId();
        if (sessionKey) {
          httpCreateParams['headers'][SESSION_HEADER] = sessionKey;
        }
        const impersonated = UserStorage.getImpersonatedUserID();
        if (impersonated && !networkAction.noImpersonation) {
          options.params = options.params || {};
          options.params['as_user'] = impersonated;
        }

        let HTTP = axios.create(httpCreateParams);

        return new Promise((resolve, reject) => {
          HTTP(options)
            .then(response => {
              UserStorage.setSessionId(response.headers[SESSION_HEADER]);
              if (isUndefined(response.data)) {
                reject(response);
                return;
              }

              if (isFunction(networkAction.response)) {
                resolve(networkAction.response(response.data, options));
              } else {
                resolve(response.data);
              }
            })
            .catch(response => {
              if (axios.isCancel(response)) {
                return;
              }
              console.error('Catch error in requesting :', url, ':', response.response);
              if (errorBlock) {
                errorBlock(response.response);
              }

              if (response.response) {
                let status = response.response.status;

                if (status === 401) {
                  // unauthorized
                  // clear up user storage , user info is failing.
                  // UserStorage.userLogout();
                  // window.location.reload();
                }
              }

              reject(response);
              return;
            });
        });
      };
    }
  }

  authorizationHeader() {
    const apiKey = UserStorage.getUserAPIKey();
    return apiKey ? `apikey ${apiKey}` : null;
  }
}

export default new Network();
